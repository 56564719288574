/* eslint-disable prefer-const */
/* eslint-disable no-unused-expressions */
import React, { useRef } from 'react'

import Slider from 'react-slick'
import { graphql, StaticQuery } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'

import AniLink from './animatelink'

const BlogQuery = ({ isMobile }) => {
    return (
        <StaticQuery
            query={graphql`
                query BlogQuery {
                    allCodeandsoulInsights(
                        sort: { fields: [post_date], order: DESC }
                    ) {
                        edges {
                            node {
                                id
                                post_id
                                description
                                post_date
                                title
                                slug
                            }
                        }
                    }
                }
            `}
            render={data => <Blog data={data} isMobile={isMobile} />}
        />
    )
}

const Blog = ({ isMobile, data }) => {
    const customeSlider = useRef()

    const gotoNext = () => {
        customeSlider.current.slickNext()
    }

    const gotoPrev = () => {
        customeSlider.current.slickPrev()
    }

    const BlogItem = ({ item }) => {
        return (
            <div key={`blog-${item.node.id} `}>
                <div className="item">
                    <div>
                        <span className="id-blog">{item.node.post_id}</span>

                        <h3
                            className="title"
                            dangerouslySetInnerHTML={{
                                __html: item.node.title,
                            }}
                        />
                        <span className="description">
                            {' '}
                            {item.node.description}{' '}
                        </span>
                    </div>

                    <AniLink
                        link={`/insight/${item.node.slug}`}
                        className="read-more"
                    >
                        more
                    </AniLink>
                </div>
            </div>
        )
    }

    let settings = {
        dots: false,
        infinite: data.allCodeandsoulInsights.edges.length > 2,
        speed: 500,
        slidesToShow: isMobile ? 1 : 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        arrows: false,
    }

    return (
        <section className="blog" id="blogSection" datanav="white">
            <div className="container">
                <Slider {...settings} ref={customeSlider}>
                    {data.allCodeandsoulInsights.edges.map((item, id) => (
                        <BlogItem key={id} item={item} />
                    ))}
                </Slider>

                <div className="button-blog">
                    {/* <div className="wrap-blog-loading">
                        <a href="#">Carregar mais insights</a>
                    </div> */}
                    <div className="wrap-blog-buttons">
                        <div className="arrows">
                            <span className="arrow left" onClick={gotoPrev} />
                            <span className="arrow right" onClick={gotoNext} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default injectIntl(BlogQuery)
